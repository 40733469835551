header {
    width: 100%;
    height: 100%;
    display: flex;
    position: absolute;
    background: rgb(35,37,49);
    background: -moz-linear-gradient(126deg, rgba(35,37,49,1) 0%, rgba(45,47,59,1) 100%);
    background: -webkit-linear-gradient(126deg, rgba(35,37,49,1) 0%, rgba(45,47,59,1) 100%);
    background: linear-gradient(126deg, rgba(35,37,49,1) 0%, rgba(45,47,59,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#232531",endColorstr="#2d2f3b",GradientType=1);
}
span a{
    color: #fff;
    text-decoration: none;
}
h2 span {
    color: #fff;
    font-size: 20px;
    letter-spacing: 1px;
    font-weight: 500;
    text-align: center;
    bottom: 10px;
    position: relative;
}

h3 span {
    color: #fff;
    font-size: 14px;
    letter-spacing: 2px;
    font-weight: 500;
    text-align: center;
    padding: 0;
    margin: 0;
}

h4 span {
    color: #fff;
    font-size: 12px;
    font-weight: 500;
    text-align: center;
    top: -4px;
    position: relative;
}
h3 span a{
    color:#0FEE7A;
}
.display-t.js-fullheight {
    display: table;
    width: 100%;
}

.display-tc.js-fullheight.animate-box {
    position: fixed;
    left: 50%;
    margin-left: -150px;
    width: 300px;
    height: 400px;
    top: 50%;
    margin-top: -200px;
    text-align: center;
}

.display-tc.js-fullheight.animate-box ul {
    outline: none;
    list-style: none;
    padding: 0;
    margin: 0;
    margin-top: 20px;
}

.display-tc.js-fullheight.animate-box ul li {
    margin: 0;
    padding: 0;
    list-style: none;
    display: -moz-inline-stack;
    display: inline-block;
    zoom: 1;
}

.display-tc.js-fullheight.animate-box ul li a {
    text-decoration: none;
    color: #fff;
    font-size: 22px;
    padding: 10px;
    margin: 5px;
    cursor: pointer;
}

.display-tc .profile-thumb, .fh5co-cover .display-tc .profile-thumb {
    background: url('../../assents/img/mateus-doimo.jpg');
    background-size: cover !important;
    background-position: center right !important;
    background-repeat: no-repeat;
    position: relative;
    height: 200px;
    width: 200px;
    margin: 0 auto;
    margin-bottom: 30px;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    border-radius: 50%;
    overflow: hidden;
	box-shadow: 0 5px 15px 0px rgba(0,0,0,0.6);
	transform: translatey(0px);
	animation: float 6s ease-in-out infinite;
}
@keyframes float {
	0% {
		box-shadow: 0 5px 15px 0px rgba(0,0,0,0.6);
		transform: translatey(0px);
	}
	50% {
		box-shadow: 0 25px 15px 0px rgba(0,0,0,0.2);
		transform: translatey(-20px);
	}
	100% {
		box-shadow: 0 5px 15px 0px rgba(0,0,0,0.6);
		transform: translatey(0px);
	}
}
