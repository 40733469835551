* {
    margin: 0;
    padding: 0;
    outline: none;
}

body {
    font-weight: 400;
    font-size: 16px;
    line-height: 1.7;
    color: #4d4d4d;
    font-family: "Space Mono", Arial, serif;
    background: rgb(35, 37, 49);
    background: -moz-linear-gradient(126deg, rgba(35, 37, 49, 1) 0%, rgba(45, 47, 59, 1) 100%);
    background: -webkit-linear-gradient(126deg, rgba(35, 37, 49, 1) 0%, rgba(45, 47, 59, 1) 100%);
    background: linear-gradient(126deg, rgba(35, 37, 49, 1) 0%, rgba(45, 47, 59, 1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#232531", endColorstr="#2d2f3b", GradientType=1);
}

.redirecionando {
    color: #fff;
    display: flex;
    justify-content: center;
    height: 100%;
    width: 100%;
    align-items: center;
    position: absolute;
    background: rgb(35, 37, 49);
    background: -moz-linear-gradient(126deg, rgba(35, 37, 49, 1) 0%, rgba(45, 47, 59, 1) 100%);
    background: -webkit-linear-gradient(126deg, rgba(35, 37, 49, 1) 0%, rgba(45, 47, 59, 1) 100%);
    background: linear-gradient(126deg, rgba(35, 37, 49, 1) 0%, rgba(45, 47, 59, 1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#232531", endColorstr="#2d2f3b", GradientType=1);
}